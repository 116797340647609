
export const config = {
  exp: {
    CREDENTIALS: {
      email: "insights@experionmea.com",
      password: "User@mea#2024"
    },
    ROUTES: {
      login: '/',
      home: '/vedhik',
      mednet: '/mednet',
      accenture: '/accenture',
      mahindra: '/mahindra',
      stanford: '/stanford',
      skyhigh: '/skyhigh',
      nissan: '/nissan',
      vineyard: '/vineyard',
      hicas: '/hicas'
    },
    MENUS: [
      { label: "Vedhik", key: "home", path: "/vedhik", reportId: "d92c879e-c3d3-4899-b23c-787446745a58", dataSetId: null },
      { label: "Mednet", key: "mednet", path: "/mednet", reportId: "564d3250-f406-4834-979f-447455140967", dataSetId: null },
      { label: "Accenture", key: "accenture", path: "/accenture", reportId: "b2d6734c-3e2a-4dcb-9e27-fbbf62c704a2", dataSetId: null },
      { label: "Mahindra", key: "mahindra", path: "/mahindra", reportId: "88a2da84-a936-43de-8356-520a772936e1", dataSetId: null },
      { label: "Stanford", key: "stanford", path: "/stanford", reportId: "c0b56bf2-931e-4d5d-a807-431faf0cd41f", dataSetId: null },
      { label: "Skyhigh", key: "skyhigh", path: "/skyhigh", reportId: "308b6afb-7d31-4495-9150-ddf54c0b9472", dataSetId: "6cd2eb8d-4bb1-4aaf-a7e8-a81fcf209192" },
      { label: "Nissan", key: "nissan", path: "/nissan", reportId: "d4c444fa-3363-4ae3-8967-6ec789567730", dataSetId: null },
      { label: "Vineyard", key: "vineyard", path: "/vineyard", reportId: "8b10abde-3e30-43c0-bcb1-aa9f5aa50038", dataSetId: "c55d08c1-0cfb-4e5c-a2e9-aae2b5401967" },
      { label: "Hicas", key: "hicas", path: "/hicas", reportId: "1ebb095a-12ee-4487-9e17-8e37a2faba9a", dataSetId: null },
    ]
  },

  lni: {
    CREDENTIALS: {
      email: "insights@learnninspire.com",
      password: "User@lni#2024"
    },
    ROUTES: {
      login: '/',
      home: '/club1',
      club2: '/club2',
      club3: '/club3'
    },
    MENUS: [
      { label: "Club 1", key: "home", path: "/club1", reportId: "860a08f6-ff34-48fc-b8c1-595ca6b81831", dataSetId: "b46ae006-ebb7-4cc8-a4c6-9bae0d0c2e9a" }
    ]
  },

  jdedu: {
    CREDENTIALS: {
      email: "insights@jdedu.com",
      password: "User@jdedu#2024"
    },
    ROUTES: {
      login: '/',
      home: '/club1',
      club2: '/club2',
      club3: '/club3'
    },
    MENUS: [
      { label: "Club 1", key: "home", path: "/club1", reportId: "860a08f6-ff34-48fc-b8c1-595ca6b81831", dataSetId: "b46ae006-ebb7-4cc8-a4c6-9bae0d0c2e9a" }
    ]
  }
}
