import React from "react";
import "./sidemenu.css";
import { IconHome, IconInsight } from "./Icons";
import { useLocation, useNavigate } from "react-router";
import { config } from "../config";

const SideMenu = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const deploymentType =  process.env.REACT_APP_DEPLOYMENT_TARGET
    const menu = config[deploymentType].MENUS
  // const menu = 
  // [
  //   {
  //     value: 1,
  //     label: "Vedhik",
  //     path: "/vedhik",
  //     // icon:<IconHome/>
  //   },
  //   {
  //     value: 2,
  //     label: "Mednet",
  //     path: "/mednet",
  //     // icon:<IconInsight/>
  //   },
  //   {
  //     value: 3,
  //     label: "Accenture",
  //     path: "/accenture",
  //     // icon:<IconInsight/>
  //   },
  //   {
  //     value: 4,
  //     label: "Mahindra",
  //     path: "/mahindra",
  //     // icon:<IconInsight/>
  //   },
  //   {
  //     value: 5,
  //     label: "Stanford",
  //     path: "/stanford",
  //     // icon:<IconInsight/>
  //   },
  //   {
  //     value: 6,
  //     label: "Skyhigh",
  //     path: "/skyhigh",
  //     // icon:<IconInsight/>
  //   },
  //   {
  //     value: 7,
  //     label: "Nissan",
  //     path: "/nissan",
  //     // icon:<IconInsight/>
  //   },
  //   {
  //     value: 8,
  //     label: "Vineyard",
  //     path: "/vineyard",
  //     // icon:<IconInsight/>
  //   },
  //   {
  //     value: 9,
  //     label: "Hicas",
  //     path: "/hicas",
  //     // icon:<IconInsight/>
  //   },

  //   ];
    
    const handleLogout = () => {
        navigate('/')
        localStorage.clear('auth')
    }

  return (
      <div className="menu-container">
          <div className="menu">
      {menu.map((item) => (
        <button
          key={item.id}
          id={item.id}
          className={`${location.pathname === item.path ? 'active' : ''} menu-button`}
          onClick={() => navigate(item.path)}
        >
              { item.icon }  { item.label }
        </button>
      ))}
              </div>
          <button className='logout-button' onClick={() => handleLogout()}> Log Out </button>
    </div>
  );
};

export default SideMenu;
